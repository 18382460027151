<template>
  <v-dialog :value="true" persistent width="750">
    <v-card>
      <v-card-title v-if="adjust">
        <span class="modal-title">
          {{ $t('Weather.adjust_precipitation') }}
        </span>
        <v-spacer />
        <v-btn icon @click="closeModal" right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="adjust" class="pb-0 pt-6" style="background: #f5f5f5">
        <v-skeleton-loader
          v-if="loadingPrecipitation"
          height="218px"
          type="image"
          width="auto"
        />
        <v-alert
          v-else-if="isNewFarm"
          style="color: #4f430c"
          color="#fff0a6"
          type="warning"
          icon="mdi-alert"
          class="alert-new-farm"
        >
          {{ $t('Weather.alert') }}
        </v-alert>
        <v-item-group>
          <v-container class="pb-0">
            <v-col cols="12" class="pb-0 px-0">
              <v-row no-gutters justify="center" class="px-1">
                <v-col
                  style="margin-right: 5px"
                  v-for="precipitation_day in precipitation.prec_mm_day"
                  :key="precipitation_day.date"
                >
                  <v-row
                    no-gutters
                    class="text-center caption grow day-week"
                    justify="center"
                  >
                    {{ dayWeek(precipitation_day.date) }}
                  </v-row>
                  <v-row
                    no-gutters
                    class="text-center caption grow day-number"
                    justify="center"
                  >
                    {{ dayMonth(precipitation_day.date) }}
                  </v-row>
                  <v-row no-gutters class="grow condition-day" justify="center">
                    <v-icon>{{ dayCondition(precipitation_day.date) }}</v-icon>
                  </v-row>
                  <v-row
                    style="color: #0a5a73; margin-bottom: 4px"
                    no-gutters
                    class="grow"
                    justify="center"
                  >
                    {{ dayPrecipitation(precipitation_day.date) }}
                  </v-row>
                  <v-row no-gutters class="grow" justify="center">
                    <v-col
                      class="text-center caption orange--text text--darken-1"
                    >
                      <converter-field
                        :label="$unitMeasures['rainfall'][$currentLanguage()]"
                        v-model="
                          observed_precipitation[precipitation_day.date]
                            .observed_mm
                        "
                        unit-measure="rainfall"
                        :key="$currentLanguage()"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-item-group>
      </v-card-text>
      <v-card-text v-else class="pt-5 pb-0" style="background: #f5f5f5">
        <v-container>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-icon class="adjust-icon">mdi-check</v-icon>
            </v-col>
            <v-col cols="12" class="text-center">
              <span class="adjust-title">
                {{ $t('Weather.adjusted_title') }}
              </span>
            </v-col>
            <v-col cols="12" class="text-center adjust-text">
              {{ $t('Weather.adjusted_text') }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-btn
          v-if="adjust"
          class="white--text btn-cancel"
          depressed
          color="rgba(0,0,0,0.4)"
          @click="closeModal"
        >
          {{ $t('cancelar') }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="adjust"
          class="btn-adjust white--text"
          color="#39Af49"
          :loading="saving"
          @click="save"
        >
          {{ $t('Weather.adjust') }}
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <v-btn
          v-if="!adjust"
          class="btn-adjust white--text"
          color="#39AF49"
          @click="closeModal"
        >
          <span class="px-2">{{ $t('Weather.close') }}</span>
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ConverterField from '@/components/Form/ConverterField.vue'
import moment from 'moment'
import { convertToTargetUnit } from '@/components/Form/converter'
import { isDevMode } from '@/utils/envConfigs'
import { mapGetters } from 'vuex'

export default {
  name: 'PrecipitationModal',

  components: {
    ConverterField,
  },

  data() {
    return {
      adjust: true,
      loadingPrecipitation: false,
      observed_precipitation: [],
      precipitation: [],
      saving: false,
    }
  },

  async created() {
    await this.fetchPrecipitation(this.currentFarmId)
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    isNewFarm() {
      const today = moment()
      const created = moment(this.precipitation.farmCreatedAt)
      const newFarmDaysPeriod = 10
      return today.diff(created, 'days') < newFarmDaysPeriod
    },
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    async fetchPrecipitation(id) {
      // Endpoint doesn't work locally yet, it's paid
      if (!isDevMode || process.env.VUE_APP_MOCK_MIRAGE) {
        this.loadingPrecipitation = true
        try {
          const { data } = await this.$api.fazendas.precipitationOld(id)
          if (data) {
            this.precipitation = data
            this.observed_precipitation = data.obs_prec_mm_day
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.loadingPrecipitation = false
        }
      }
    },
    dayWeek(date) {
      const dia = this.moment(this.precipitation.prec_mm_day[date].date)
        .locale(this.$currentLanguage())
        .format('ddd')

      return dia
    },
    dayMonth(date) {
      const day = this.moment(this.precipitation.prec_mm_day[date].date)
        .locale(this.$currentLanguage())
        .format('DD')
      const today = this.moment().locale(this.$currentLanguage()).format('DD')
      let label = this.$t('dias_semana.hoje')
      label = label[0].toUpperCase() + label.slice(1)
      return day === today ? label : day
    },
    dayCondition(date) {
      const mm = this.precipitation.prec_mm_day[date].cons_mm
      if (mm < 3) return 'mdi-weather-sunny'
      else if (mm > 3 && mm < 5) return 'mdi-weather-cloudy'
      else return 'mdi-weather-pouring'
    },
    dayPrecipitation(date) {
      const precipitation = this.precipitation.prec_mm_day[date].cons_mm
      return precipitation == null
        ? '-'
        : convertToTargetUnit(precipitation, 'rainfall') +
            `${this.$unitMeasures['rainfall'][this.$currentLanguage()]}`
    },
    async save() {
      this.saving = true
      try {
        const { data } = await this.$api.fazendas.savePrecipitation(
          this.precipitation.farmId,
          this.observed_precipitation
        )
        if (data.farm == this.precipitation.farmId) {
          this.adjust = false
        }
        this.$emit('update-weather')
      } catch (error) {
        console.error(error)
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style scoped>
.modal-title {
  font-size: 19px;
  font-weight: 500;
  line-height: 20px;
  color: #1a2b46;
}
.btn-cancel {
  margin: 11px 0 12px 24px;
}
.btn-adjust {
  margin: 14px 16px 15px 0;
}
.day-week {
  font-size: 10px;
  line-height: 18px;
  color: #788476;
  font-weight: 400;
}
.day-number {
  font-size: 12px;
  line-height: 18px;
  color: #5b6459;
  font-weight: 700;
}
.condition-day {
  margin: 7px;
}
.adjust-icon {
  font-weight: 900;
  font-size: 52px;
  line-height: 10px;
  color: #2b8337;
}
.adjust-title {
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: #1a2b46;
}
.adjust-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5b6459;
}
</style>

<style>
.alert-new-farm .theme--dark.v-icon {
  color: #f39912 !important;
  align-self: center;
}
</style>
